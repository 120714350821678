<template>
  <div class="refer-container">
    <div class="form-wrapper" :class="{ mobile: isMobile }">
      <div class="header">
        <div class="title-box">
          <h2 class="title">合作咨询</h2>
          <div class="title-line"></div>
        </div>
        <p class="subtitle">让我们开启愉快的合作之旅吧</p>
      </div>

      <el-form
        :model="formData"
        :rules="rules"
        ref="formRef"
        label-position="top"
        size="large"
        class="custom-form"
      >
        <div class="form-grid">
          <el-form-item label="公司名称" prop="companyName">
            <el-input
              v-model="formData.companyName"
              placeholder="请输入公司名称"
            >
              <template #prefix>
                <i class="fas fa-building"></i>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item label="联系人" prop="contacts">
            <el-input
              v-model="formData.contacts"
              placeholder="请输入联系人姓名"
            >
              <template #prefix>
                <i class="fas fa-user"></i>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="formData.phone" placeholder="请输入联系电话">
              <template #prefix>
                <i class="fas fa-phone"></i>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item label="电子邮箱" prop="email">
            <el-input v-model="formData.email" placeholder="请输入电子邮箱">
              <template #prefix>
                <i class="fas fa-envelope"></i>
              </template>
            </el-input>
          </el-form-item>
        </div>

        <el-form-item label="详细说明">
          <el-input
            type="textarea"
            v-model="formData.description"
            placeholder="请详细描述您的合作需求"
            :rows="5"
            resize="none"
          ></el-input>
        </el-form-item>

        <div class="button-group">
          <el-button @click="resetForm" class="reset-btn">重置</el-button>
          <el-button type="primary" @click="submitForm" class="submit-btn"
            >提交咨询</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getAdd } from "@/api/refer";

export default {
  name: "ReferPage",
  data() {
    return {
      windowWidth: window.innerWidth,
      formData: {
        companyName: "",
        contacts: "",
        phone: "",
        email: "",
        description: "",
      },
      rules: {
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        email: [
          { required: false }, // 设置为非必填
          {
            type: "email",
            message: "请输入正确的邮箱格式",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    isMobile() {
      return this.windowWidth < 768;
    },
    formWidth() {
      if (this.windowWidth < 768) {
        return "90%";
      } else if (this.windowWidth < 1200) {
        return "60%";
      } else {
        return "50%";
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    submitForm() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          getAdd(this.formData).then((response) => {
            if (response.data.code == 200) {
              this.$message.success(response.data.msg);
              this.$router.push("/");
              return;
            } else {
              this.$message.error("操作失败，请重新提交");
              return;
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.formRef.resetFields();
    },
  },
};
</script>

<style scoped>
.refer-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #f0fffe 0%, #f9fffe 100%);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  position: relative;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.refer-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
      circle at 25% 25%,
      rgba(255, 255, 255, 0.2) 0%,
      transparent 50%
    ),
    radial-gradient(
      circle at 75% 75%,
      rgba(255, 255, 255, 0.2) 0%,
      transparent 50%
    );
  pointer-events: none;
}

.form-wrapper {
  background: rgba(255, 255, 255, 0.95);
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 900px;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
}

.form-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(135deg, #f0fffe 0%, #f9fffe 100%);
}

.header {
  margin-bottom: 50px;
}

.title-box {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.title {
  color: #2b5876;
  font-size: 36px;
  font-weight: 700;
  margin: 0;
  background: linear-gradient(135deg, #08b692 0%, #08b692 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-line {
  flex-grow: 1;
  height: 2px;
}

.subtitle {
  color: #4b5563;
  font-size: 16px;
  margin: 0;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 30px;
}

.custom-form :deep(.el-form-item__label) {
  font-weight: 600;
  color: #08b692;
  font-size: 14px;
  padding-bottom: 8px;
}

.custom-form :deep(.el-input__inner) {
  background: rgba(240, 255, 254, 0.4);
  border: 2px solid #e2e8f0;
  height: 50px;
  border-radius: 12px;
  padding-left: 45px;
  transition: all 0.3s ease;
}

.custom-form :deep(.el-input__prefix) {
  left: 15px;
  color: #08b692;
}

.custom-form :deep(.el-textarea__inner) {
  border: 2px solid #edf2f7;
  border-radius: 12px;
  padding: 15px;
  transition: all 0.3s ease;
}

.custom-form :deep(.el-input__inner:focus),
.custom-form :deep(.el-textarea__inner:focus) {
  box-shadow: 0 0 0 3px rgba(14, 165, 233, 0.1);
  border-color: #08b692;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 40px;
}

.submit-btn,
.reset-btn {
  min-width: 120px;
  height: 45px;
  border-radius: 10px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.submit-btn {
  background: linear-gradient(135deg, #08b692 0%, #08b692 100%);
  border: none;
}

.submit-btn:hover {
  background: linear-gradient(135deg, #08b692 0%, #08b692 100%);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(37, 37, 37, 0.3);
}

.reset-btn {
  border: 2px solid #08b692;
  color: #08b692;
}

.reset-btn:hover {
  background-color: rgba(14, 165, 233, 0.1);
  border-color: #08b692;
  color: #08b692;
}

@media screen and (max-width: 768px) {
  .form-wrapper {
    padding: 30px 20px;
    border-radius: 15px;
  }

  .title {
    font-size: 28px;
  }

  .form-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .button-group {
    flex-direction: column-reverse;
  }

  .submit-btn,
  .reset-btn {
    margin-left: 0px !important;
    width: 100%;
  }
}

/* 添加输入框动画效果 */
.custom-form :deep(.el-input__inner),
.custom-form :deep(.el-textarea__inner) {
  transition: transform 0.3s ease;
}

.custom-form :deep(.el-input__inner:focus),
.custom-form :deep(.el-textarea__inner:focus) {
  transform: translateY(-2px);
}

/* 添加图标样式 */
.custom-form :deep(.fas) {
  font-size: 16px;
}

/* 覆盖 Element Plus 的默认样式 */
.submit-btn.el-button:focus {
  background: linear-gradient(135deg, #08b692 0%, #08b692 100%);
  color: #fff;
  border-color: transparent;
}

.reset-btn.el-button:focus,
.reset-btn.el-button:hover,
.reset-btn.el-button:active {
  background: transparent;
  border: 2px solid #08b692;
  color: #08b692;
}
</style>
