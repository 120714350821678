<template>
  <div class="page-container">
    <!-- 导航栏 -->
    <div class="navigation_bar" ref="navbar">
      <!-- 左边 -->
      <div class="navigation_left">
        <ul>
          <!-- <li>logo</li> -->
          <li class="Jaedeok">载德信息</li>
          <li style="color: #666" @click="handleHome()">
            首页
            <!-- <a href="" style="text-decoration: none; color: #666"></a> -->
          </li>
          <li>
            <el-dropdown style="height: 60px">
              <span class="el-dropdown-link">
                产品<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="custom-dropdown-item"
                  ><a href="#" style="text-decoration: none; color: #666"
                    >语音通知</a
                  ></el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li>
            <el-dropdown style="height: 60px">
              <span class="el-dropdown-link">
                解决方案<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="custom-dropdown-item"
                  ><a href="#" style="text-decoration: none; color: #666"
                    >语音通知</a
                  ></el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li style="color: #666" @click="handleMe">关于我们</li>
          <li @click="handleRouer" style="color: #666">申请试用</li>
        </ul>
      </div>
      <!-- 右边 -->
      <div class="navigation_right" @click="handleClick">
        <i class="iconfont icon-gengduo"></i>
      </div>
      <!-- 移动端下拉框 -->
      <el-drawer
        size="50%"
        title=""
        :visible.sync="drawer"
        direction="ttb"
        :modal="false"
        class="pull-down"
        :before-close="handleClose"
      >
        <el-menu
          :default-active="activeIndex"
          :unique-opened="true"
          @select="handleSelect"
          active-text-color="#10b593"
        >
          <el-submenu index="1">
            <template slot="title">产品</template>
            <el-menu-item index="1-1"
              ><a href="#" style="text-decoration: none; color: #111"
                >语音通知</a
              ></el-menu-item
            >
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">解决方案</template>
            <el-menu-item index="2-1"
              ><a href="#" style="text-decoration: none; color: #111"
                >语音通知</a
              ></el-menu-item
            >
          </el-submenu>
          <el-menu-item index="4">申请试用</el-menu-item>
        </el-menu>
      </el-drawer>
    </div>

    <!-- 第一个页面 -->
    <div class="one_page">
      <div class="new_ones">
        <div>
          <div class="voice">专注语音场景解决方案</div>
          <div>行业10年，解决多场景难点</div>
        </div>
      </div>
      <div class="news_on">
        <div>语音通知—解决方案</div>
      </div>
      <div class="newly">
        <div class="newly_page">
          核心网直连，可有效解决电话通知确定触达至用户侧，
          契合了电话通知要到位的重要性与不可忽略性。
          正常情况，语音通知无法解决触达问题，通知看似拨打了，
          但用户侧却没有接到该通电话。此方案可很好解决该问题，协助企业、政务解决一系列麻烦。
        </div>
        <div class="one_page_left_btn">
          <el-button
            slot="reference"
            class="btn1"
            :round="true"
            @click="handleRouer"
          >
            立即试用
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </div>
      <div class="one_pageces">
        <div class="one-page_left">
          <div>
            <h2>背景</h2>
            <p class="one-page_left_p2">
              电话通知、语音通知，在很多重要场景中，
              发起电话通知均是在比较重要的环节，
              对及时性、唤起用户有重要的意义，可在关键时候第一时间收到且唤起。
              有区别与短信，短信也能触达，相比下，短信不易第一时间提醒到被通知人，
              与电话通知呈现了不同的重要性。
            </p>
          </div>
          <div>
            <h2>特点</h2>
            <p class="one-page_left_p2">
              仅被叫自身原因无法接通（无信号和关停机），其余均可触达用户。
            </p>
          </div>
        </div>
        <div class="one-page_right">
          <img src="@/assets/test.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 第二个页面 -->
    <div class="two_page">
      <div class="two_page1">
        <div class="two_page_div1">
          <h2>确保每一次通话传达到位</h2>
          <p>企业平台直呼到用户手机，进行语音内容播报，确保用户收到消息。</p>
        </div>
        <div class="scene"><p>应用场景</p></div>
        <ul>
          <li>
            <div class="enterprise">
              <h3>企业</h3>
              <div>
                高附加值订单通知、大额网上购物、敏感支付、交通违章、
                敏感/异常账户登录确认、大额消费确认、支付通知、会员服务通知、事件通知、会议通知、门禁。
              </div>
            </div>
            <div class="enterprise_img">
              <img src="@/assets/test1.png" alt="企业" />
            </div>
          </li>
          <li>
            <div class="enterprise">
              <h3>政务</h3>
              <div>
                应急通知、政务通知、24小时应急场景、深林防火、物联网领域应急通知等业务场景。
              </div>
            </div>
            <div class="enterprise_img">
              <img src="@/assets/test2.png" alt="政务" />
            </div>
          </li>
          <li>
            <div class="enterprise">
              <h3>语音验证</h3>
              <div>
                适用于APP/网站等注册账号时，
                下发到手机的语音数字、验证码、找回密码等安全验证、支付认证、身份校验场景。
              </div>
            </div>
            <div class="enterprise_img">
              <img src="@/assets/test3.png" alt="语音验证码" />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 第三个页面 -->
    <div class="three_page">
      <div class="three_page1">
        <div>
          <p>产品优势</p>
          <h2>信息通知好帮手</h2>
        </div>
        <ul>
          <li>
            <div class="three_page_all">
              <i class="iconfont icon-shoujihaoma"></i>
              <div>
                <h3>号码优势</h3>
                <div>号码独享，码号无标记。</div>
              </div>
            </div>
          </li>
          <li>
            <div class="three_page_all">
              <i class="iconfont icon-diquguanli"></i>
              <div>
                <h3>地区优势</h3>
                <div>31省，365地级市码号。</div>
              </div>
            </div>
          </li>
          <li>
            <div class="three_page_all">
              <i class="iconfont icon-zhuanyetuandui"></i>
              <div>
                <h3>专业团队</h3>
                <div>
                  针对业务场景需求提供完善解决方案，
                  满足各类通知场景，提供7*24小时服务保证。
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="three_page_all">
              <i class="iconfont icon-gaoxiaofuwu"></i>
              <div>
                <h3>高效服务</h3>
                <div>稳定，专业团队，1v1服务。</div>
              </div>
            </div>
          </li>
          <li>
            <div class="three_page_all">
              <i class="iconfont icon-bianjie1"></i>
              <div>
                <h3>便捷接入</h3>
                <div>
                  API或SDK，接入简单，接口文档易于理解，技术团队提供实时在线支持。
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="three_page_all">
              <i class="iconfont icon-anquanyinsi1"></i>
              <div>
                <h3>安全稳定</h3>
                <div>支持加密传输，采用容备机制，数据脱敏保存。</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 新增关于我们页面 -->
    <div class="mAbout_Use">
      <div class="mAbout_Use_one">
        <div class="me">关于我们</div>
        <div class="two">
          重庆载德信息技术有限公司，公司团队目前在行业沉淀10年，期间，自主研发各类系统平台，多场景解决方案，为几百家企业和多家上市公司解决了多种技术难题，在业内拥有良好口碑。
        </div>
      </div>
    </div>
    <!-- 第四个页 -->
    <div class="four_page">
      <div class="four_page1">
        <div class="four_page_div1">
          <div class="four_page_div1_div1">联系我们</div>
          <p class="four_page_div1_p2">商务联系方式：15178950401</p>
          <p class="four_page_div1_p2">
            公司地址：重庆市巴南区渝南大道251号19-1
          </p>
        </div>
        <div class="four_page_div2">
          <div>
            <p>关注我们</p>
          </div>
          <div class="four_page_div1_div2">
            <div>
              <p>
                <img src="@/assets/qr.png" alt="" />
              </p>
              <p>最新动态</p>
            </div>
          </div>
        </div>
      </div>
      <div class="four_page2">
        <div class="four_page2_div">
          <div class="four_page2_div1">
            <div class="four_page2_div1_left">
              <div>
                <p>
                  © Copyright 2024 载德 All Rights Reserved
                  重庆载德信息技术有限公司
                </p>
              </div>
              <p>
                <!-- @click.prevent="showImage" -->
                <a href="#">增值电信业务经营许可证：B2-20234990</a>
                <a href="https://beian.miit.gov.cn/#/Integrated/index">
                  ICP备案：渝ICP备2023002121号-1</a
                >
                <image-popup :visible="isPopupVisible" @close="closePopup" />
              </p>
            </div>
            <div class="four_page2_div1_right"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 固定标签 -->
    <div class="immobilization" v-if="showFixedIcons">
      <div class="fixed-icons">
        <div class="ficed-item">
          <el-popover
            placement="left-start"
            title="咨询热线"
            width="200"
            trigger="hover"
          >
            <template #default>
              <div class="popover-content" @click="makeCall">15178950401</div>
            </template>
            <template #reference>
              <div class="fixed-icons-div">
                <i class="iconfont icon-dianhua"></i>
              </div>
            </template>
          </el-popover>
        </div>
      </div>

      <div>
        <el-backtop class="location">
          <i class="iconfont icon-xiangshang"></i>
        </el-backtop>
      </div>
    </div>

    <div class="chat-toggle" @click="handleRouer">
      <span>申请试用</span>
    </div>

    <!-- 添加二维码咨询按钮 -->
    <div class="qr-toggle" @click="showQrCode">
      <span>扫码咨询</span>
    </div>

    <!-- 添加二维码弹窗 -->
    <div class="qr-modal" v-if="showQr" @click="hideQrCode">
      <div class="qr-content" @click.stop>
        <img src="@/assets/qr.png" alt="咨询二维码" />
        <p>扫码咨询</p>
      </div>
    </div>
  </div>
</template>

<script>
import ImagePopup from "@/components/ImagePopup.vue";

export default {
  name: "Home-page",
  components: {
    ImagePopup,
  },
  data() {
    return {
      showFixedIcons: true,
      isFirstPage: true,
      drawer: false,
      activeIndex: "1",
      isPopupVisible: false,
      isOpen: false,
      userMessage: "",
      showQr: false,
    };
  },
  methods: {
    getCurrentTime() {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    },
    showImage() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
    },
    // 调用移动端电话
    makeCall() {
      this.$emit("makeCall");
      // const phoneNumber = "15178950401";
      // const isMobile = /Mobi|Android/i.test(navigator.userAgent);

      // if (isMobile) {
      //   window.location.href = `tel:${phoneNumber}`;
      // }
    },
    handleScroll() {
      const navbar = this.$refs.navbar;
      if (!navbar) {
        return;
      } else {
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const pageHeight = window.innerHeight;

        if (scrollTop > this.lastScrollTop) {
          navbar.style.top = `-${navbar.offsetHeight}px`;
        } else {
          navbar.style.top = "0";
        }

        this.isFirstPage = scrollTop < pageHeight;

        navbar.style.backgroundColor = this.isFirstPage ? "#f3fffe" : "#ffffff";
      }
    },
    handleRouer() {
      this.$router.push("/refer");
    },
    handleHome() {
      const aboutSection = this.$el.querySelector(".one_page");
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: "smooth" });
      }
    },
    // 关于我们
    handleMe() {
      const aboutSection = this.$el.querySelector(".mAbout_Use");
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: "smooth" });
      }
    },
    // 移动端
    handleClick() {
      this.drawer = true;
    },
    handleSelect(key) {
      if (key == "4") {
        this.handleRouer();
      }
    },
    handleClose() {
      this.activeIndex = "";
      this.drawer = false;
    },
    toggleChat() {
      this.isOpen = !this.isOpen;
    },
    sendMessage() {
      if (this.userMessage.trim()) {
        // 处理发送消息的逻辑
        this.userMessage = "";
      }
    },
    showQrCode() {
      this.showQr = true;
    },
    hideQrCode() {
      this.showQr = false;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less">
/* 添加渐变背景容器样式 */
.page-container {
  background: linear-gradient(
    180deg,
    #f3fffe 0%,
    /* 第一页背景色 */ #f7fffd 25%,
    /* 渐变过渡色 */ #fbffff 50%,
    /* 渐变过渡色 */ #ffffff 100% /* 最后页背景色 */
  );
}

/* 固定标签 */
.immobilization {
  z-index: 10086;
  position: fixed !important;
  top: 30% !important;
  right: 20px !important;
  .location {
    position: fixed !important;
    top: 60% !important;
    right: 20px !important;
  }
  .icon-xiangshang {
    color: #00a985;
  }
  .popover-content {
    font-weight: bold;
    color: #08b692;
    font-size: 26px;
  }
  .fixed-icons {
    color: #8f8f8f;
    padding: 0.5vh;
    border-radius: 100%;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .fixed-icons-div:hover {
    color: white;
    background-color: #08b692;
  }
  .fixed-icons-div {
    text-align: center;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 50%;
    .icon-dianhua {
      font-size: 25px;
    }
  }
  .fixed-icons-div2:hover {
    color: white;
    background-color: #08b692;
  }
  .fixed-icons-div2 {
    text-align: center;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 50%;
    .icon-kefu {
      font-size: 25px;
    }
  }
}

/* 导航栏 */
.navigation_bar {
  padding: 0px 30px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 900;
  justify-content: space-between;
  height: 100px;
  .navigation_left {
    ul {
      display: flex;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    .Jaedeok {
      font-family: "Microsoft YaHei", "微软雅黑", sans-serif;
      font-size: 30px;
      font-weight: bold;
      background: linear-gradient(45deg, #23afa3, #34d190);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: 2px;
    }
    li {
      height: 100px;
      line-height: 100px;
      list-style: none;
      color: #202945;
      margin-left: 70px;
      img {
        width: 70px;
        height: 70px;
        line-height: 100px;
      }
      .el-dropdown-link {
        cursor: pointer;
        font-size: 16px;
      }
      .el-icon-arrow-down {
        font-size: 16px;
      }
    }
    li:hover {
      color: #00a985;
      cursor: pointer;
    }
  }
}

/* 第一个页面 */
.one_page {
  overflow: hidden;
  // 删除 background-color: #f3fffe;
}

.new_ones {
  text-align: center;
  width: 60%;
  margin: 180px auto 100px;
  font-size: 20px;
  font-weight: 900;
  color: #00a985;
  .voice {
    font-size: 36px;
    margin: 100px 0 30px 0;
  }
}

.news_on {
  width: 60%;
  margin: 0 auto 20px;
  div {
    font-size: 30px;
    font-weight: 900;
    width: 70%;
    margin-left: 4vw;
    color: #00a985;
  }
}

.newly {
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin: 0 auto;
  width: 60%;
  .newly_page {
    margin-left: 4vw;
    color: #666666;
    width: 70%;
    text-indent: 2em;
  }
  .one_page_left_btn {
    width: 30%;
    text-align: center;
    .btn1 {
      position: relative;
      top: 80px;
      right: 50px;
      color: white !important;
      font-size: 20px;
      background-color: #08b692 !important;
      border-radius: 40px;
      border: none;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
}

.one_pageces {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 60%;
  .one-page_left {
    box-sizing: border-box;
    padding: 0vh 2vw 10px 4vw;
    width: 50%;
    .one-page_left_p1 {
      color: #202945;
      font-size: 30px;
      font-weight: bold;
      margin: 0px 0px 20px;
    }
    .one-page_left_p2 {
      color: #666666;
      margin: 0px 0px 55px;
      font-size: 15px;
      text-indent: 2em;
    }

    h2 {
      font-size: 35px;
      margin: 0px 0px 20px;
      color: #00a985;
    }
  }
  .one-page_right {
    width: 50%;
    text-align: end;
    img {
      width: 60%;
      height: 60%;
      margin-right: 100px;
    }
  }
}

/* 第二个页面 */
.two_page {
  padding: 50px 0px 0px 0px;
  overflow: hidden;
  .two_page1 {
    margin: 0 auto;
    width: 60%;
    max-width: 1440px;
    box-sizing: border-box;
    .two_page_div1 {
      padding-left: 70px;
      max-width: 800px;
      margin: 0px 320px 40px;
      text-align: center;
      margin: 0 auto;
      h2 {
        font-size: 40px;
        color: #202945;
      }
      p {
        color: #666666;
        font-size: 17px;
        margin: 20px 0px 0px;
      }
    }
    .scene {
      color: #00a985;
      font-size: 35px;
      text-align: center;
      margin-top: 100px;
    }
    ul {
      margin-bottom: 100px;
      padding-left: 70px;
      li:nth-child(1) {
        margin-top: 100px;
      }
      li {
        list-style: none;
        padding: 0px 0px 40px 10px;
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        .enterprise {
          color: #666;
          line-height: 1.8;
          font-size: 16px;
          width: 50%;
          h3 {
            margin: 10px 0px 25px;
            font-weight: bold;
            line-height: 1.2;
            font-size: 30px;
            color: #202945;
          }
          div {
            text-indent: 2em;
          }
        }
        .enterprise_img {
          width: 50%;
          text-align: end;
          img {
            width: 55%;
            margin-right: 100px;
          }
        }
      }
    }
  }
}

/* 第三个页面 */
.three_page {
  position: relative;
  padding: 0px 0px 100px;
  overflow: hidden;
  .three_page1 {
    position: relative;
    z-index: 5;
    margin: 0 auto;
    width: 60%;
    box-sizing: border-box;
    background-color: #fff;
    > div {
      visibility: visible;
      text-align: center;
      margin: 0 auto;
      max-width: 1000px;
      p {
        color: #00a985;
        font-size: 35px;
        margin: 0px 0px 20px;
      }
      h2 {
        color: #202945;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 50px;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: space-evenly;
      padding-left: 70px;
      li {
        list-style: none;
        display: flex;
        width: 35%;
        padding: 0px 20px 40px;
        color: #202945;
        > div {
          visibility: visible;
          background-image: linear-gradient(0deg, #ffffff 0%, #f1faf9 100%),
            linear-gradient(#ffffff, #ffffff);
          background-blend-mode: normal, normal;
          box-shadow: 0 0 63px 0 rgba(0, 169, 133, 0.1), inset 0 0 0 3px #ffffff;
          border-radius: 10px;
          display: flex;
          align-items: flex-start;
          overflow: hidden;
          position: relative;
          padding: 20px;
          width: 100%;

          .iconfont {
            position: relative;
            z-index: 5;
            margin-right: 10px;
            flex-shrink: 0;
            font-size: 40px;
            color: #00a985;
          }
          > div {
            position: relative;
            z-index: 5;

            h3 {
              font-weight: bold;
              line-height: 30px;
              color: #202945;
              font-size: 25px;
              margin-bottom: 15px;
              max-width: 1890px;
            }
            div {
              color: #666;
              min-height: 72px;
              margin: 15px 0px 0px;
              font-size: 16px;
              max-width: 1890px;
            }
          }
        }
      }
    }
  }
}

/*新增关于我们 */
.mAbout_Use {
  padding: 50px 0px 100px 0px;
  overflow: hidden;
  .mAbout_Use_one {
    margin: 0 auto;
    width: 60%;
    max-width: 1440px;
    box-sizing: border-box;
    text-align: center;
    .me {
      color: #00a985;
      font-size: 35px;
      margin: 0px 0px 20px;
    }
    .two {
      font-size: 20px;
      line-height: 40px;
      margin-left: 4vw;
    }
  }
}

/* 第四个页面 */
.four_page {
  border-top: 1px solid #ddf0ee;
  position: relative;
  padding-top: 20px;
  overflow: hidden;

  .four_page1 {
    position: relative;
    z-index: 5;
    max-width: 1280px;
    margin: 0 auto;
    width: 60%;
    padding-left: 70px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    .four_page_div1 {
      .four_page_div1_div1 {
        font-size: 18px;
      }
      .four_page_div1_p1 {
        font-size: 30px;
        color: #00a985;
        font-weight: bold;
        margin: 20px 0 40px;
      }
      .four_page_div1_p2 {
        color: #0e1734;
        font-size: 18px;
      }
    }
    .four_page_div2 {
      margin-left: 120px;
      > div {
        text-align: center;
        padding-bottom: 10px;
        p {
          font-size: 18px;
          margin: 0px 0px;
        }
      }
      > ul {
        padding-left: 0;
        font-size: 16px;
        > li {
          list-style: none;
          padding-bottom: 20px;
          a {
            color: #666;
            text-decoration: none;
          }
          a:hover {
            text-decoration: underline;
            color: #00a985;
          }
        }
      }
      .four_page_div1_div2 {
        display: flex;
        align-items: center;
        margin-top: 20px;
        justify-content: center;
        > div {
          display: block;
          text-align: center;
          padding: 20px;
          box-shadow: 0 0 10px 0 rgba(0, 169, 133, 0.1);
          border-radius: 0.05rem;
          p {
            display: block;
            text-align: center;
            width: 100px;
            img {
              width: 80px;
              height: 80px;
              max-width: 100%;
              border: 0;
              vertical-align: top;
            }
          }
        }
      }
    }
  }

  .four_page2 {
    margin-top: 50px;
    .four_page2_div {
      padding-left: 70px;
      margin: 0 auto;
      width: 60%;
      max-width: 1280px;
      box-sizing: border-box;
      .four_page2_div1 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        border-top: 1px solid #e8e8e8;
        padding: 30px 0;
        color: #999;
        .four_page2_div1_left {
          font-size: 12px;
          > div {
            word-wrap: break-word;
            word-break: break-all;
            p {
              font-size: 14px;
            }
          }
          p {
            margin-top: 10px;
            a {
              margin-right: 25px;
              color: #999;
              text-decoration: none;
              font-size: 14px;
            }
            a:hover {
              text-decoration: underline;
              color: #00a985;
            }
          }
        }
        .four_page2_div1_right {
          a {
            display: flex;
            align-items: center;
            color: #999;
            font-size: 14px;
            text-decoration: none;
            img {
              margin-right: 10px;
              max-width: 100%;
              border: 0;
              vertical-align: top;
            }
          }
          a:hover {
            text-decoration: underline;
            color: #00a985;
          }
        }
      }
    }
  }
}

/* 基础响应式设置 */
@media (max-width: 900px) {
  /*移动端菜单 */
  .pull-down {
    z-index: 10099;
  }
  .navigation_left {
    display: none;
  }

  .navigation_right {
    display: flex;
    align-items: center;
    padding-right: 20px;
    .iconfont {
      font-size: 30px;
      color: #202945;
    }
  }

  /* 第一页适配 */
  .one_page {
    .new_ones {
      width: 90%;
      margin: 80px auto 20px;
      font-size: 24px;
    }

    .news_on {
      width: 90%;
      margin: 0 auto 20px;
      text-align: center;
      div {
        font-size: 20px;
        margin: 0 auto;
        display: block;
      }
    }

    .newly {
      flex-direction: column;
      width: 90%;
      // margin-top: 80px;

      .newly_page {
        margin: 20px 0;
        font-size: 16px;
        margin: 0 auto;
      }

      .one_page_left_btn {
        width: 100%;
        text-align: center;
        margin: 20px 0;

        .btn1 {
          top: 0;
          right: 0;
        }
      }
    }

    .one_pageces {
      width: 90%;

      .one-page_left,
      .one-page_right {
        width: 100%;
        padding: 20px;

        h2 {
          font-size: 24px;
        }

        .one-page_left_p2 {
          font-size: 14px;
        }
      }

      .one-page_right {
        text-align: center;

        img {
          width: 80%;
          height: auto;
          margin-right: 0;
        }
      }
    }
  }

  /* 第二页适配 */
  .two_page {
    .two_page1 {
      width: 90%;

      .two_page_div1 {
        padding-left: 0;

        h2 {
          font-size: 24px;
        }

        p {
          font-size: 14px;
        }
      }

      ul {
        flex-direction: column;
        padding-left: 0;

        li {
          width: 100%;
          h3 {
            font-size: 20px;
          }

          div {
            font-size: 14px;
          }
        }
      }
    }
  }

  /* 第三页适配 */
  .three_page {
    .three_page1 {
      width: 90%;

      ul {
        padding-left: 0;

        li {
          width: 100%;
          padding: 10px;

          .three_page_all {
            padding: 15px;

            h3 {
              font-size: 20px;
            }

            div {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  /* 第四页适配 */
  .four_page {
    .four_page1 {
      width: 90%;
      padding-left: 0;
      flex-direction: column;

      .four_page_div2 {
        margin-left: 0;
        margin-top: 30px;
      }
    }

    .four_page2 {
      .four_page2_div {
        width: 90%;
        padding-left: 0;
      }
    }
  }

  /* 固定标签适配 */
  .immobilization {
    .fixed-icons {
      transform: scale(0.8);
      right: 10px !important;
      padding: 9px;
    }
    .location {
      right: 24px !important;
    }
  }
}

/* 更小屏幕的适配 */
@media (max-width: 480px) {
  /*移动端菜单 */
  .pull-down {
    z-index: 2000;
  }
  .navigation_bar {
    padding: 0 15px;
    height: 60px;
  }

  .one_page {
    .new_ones {
      font-size: 22px;
    }
    .news_on {
      div {
        font-size: 20px;
        text-align: center;
      }
    }

    .newly {
      .newly_page {
        font-size: 14px;
      }

      .one_page_left_btn {
        .btn1 {
          font-size: 16px;
        }
      }
    }

    .one_pageces {
      width: 90%;

      .one-page_left,
      .one-page_right {
        width: 100%;
        padding: 20px;

        h2 {
          font-size: 24px;
        }

        .one-page_left_p2 {
          font-size: 14px;
        }
      }

      .one-page_right {
        text-align: center;

        img {
          width: 80%;
          height: auto;
        }
      }
    }
  }

  .two_page,
  .three_page,
  .four_page {
    h2 {
      font-size: 20px !important;
    }

    p {
      font-size: 30px;
    }
  }
  .icon-dianhua {
    font-size: 30px !important;
  }
  .icon-kefu {
    font-size: 30px !important;
  }
}

.chat-widget {
  z-index: 10099;
  position: fixed;
  bottom: 0px;
  right: 10px;
  width: 350px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
}

.chat-header {
  background-color: #00a985;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  i:hover {
    cursor: pointer;
  }
}

.chat-body {
  padding: 10px;
  max-height: 300px;
  height: 300px;
  overflow-y: auto;
}

.message {
  margin-bottom: 10px;
}

.time {
  font-size: 12px;
  color: #999;
}

.chat-footer {
  display: flex;
  padding: 10px;
  border-top: 1px solid #eee;
}

.chat-footer input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 5px;
}

.chat-footer button {
  background-color: #00a985;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.chat-toggle {
  z-index: 10099;
  position: fixed;
  top: 21%;
  right: -5px;
  background-color: #00a985;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.two_page {
  .two_page1 {
    ul {
      @media (max-width: 873px) {
        padding-left: 0;

        li {
          display: flex;
          flex-direction: column;
          margin-bottom: 40px;
          padding: 0 15px;

          .enterprise {
            width: 100%;
            order: 1;
            text-align: left;
            margin-bottom: 20px;

            h3 {
              font-size: 24px;
              margin: 10px 0;
              text-align: left;
              color: #202945;
            }

            div {
              text-indent: 2em;
              line-height: 1.6;
              font-size: 14px;
              color: #666;
            }
          }

          .enterprise_img {
            width: 100%;
            order: 2;
            text-align: center;
            margin-top: 20px;

            img {
              width: 60%;
              max-width: 300px;
              height: auto;
              margin-right: 0;
            }
          }
        }
      }

      @media (max-width: 480px) {
        li {
          padding: 0 10px;

          .enterprise {
            h3 {
              font-size: 20px;
            }

            div {
              font-size: 14px;
            }
          }

          .enterprise_img {
            img {
              width: 60%;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

/* 新增关于我们移动端适配 */
@media (max-width: 900px) {
  .mAbout_Use {
    padding: 20px 0; /* 调整上下内边距 */

    .mAbout_Use_one {
      width: 90%; /* 调整宽度 */
      margin: 0 auto; /* 居中对齐 */
      text-align: center; /* 文本居中 */

      .me {
        font-size: 28px; /* 调整标题字体大小 */
      }

      .two {
        font-size: 16px; /* 调整内容字体大小 */
        line-height: 28px; /* 调整行高 */
        margin-left: 0; /* 移除左边距 */
      }
    }
  }
}

/* 二维码咨询按钮样式 */
.qr-toggle {
  z-index: 10099;
  position: fixed;
  top: 15%;
  right: -5px;
  background-color: #00a985;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

/* 二维码弹窗样式 */
.qr-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10100;
}

.qr-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;

  img {
    width: 200px;
    height: 200px;
  }

  p {
    margin-top: 10px;
    color: #333;
    font-size: 16px;
  }
}

/* 移动端适配 */
@media (max-width: 900px) {
  .qr-toggle {
    padding: 8px;
  }

  .qr-content {
    img {
      width: 150px;
      height: 150px;
    }
  }
}
</style>
