<template>
  <div id="app">
    <router-view @makeCall="makeCall"></router-view>
    <!-- 全局退出确认弹窗 -->
    <el-dialog
      title="确认离开？"
      :visible.sync="showExitDialog"
      width="300px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center
      custom-class="exit-dialog"
    >
      <div class="exit-dialog-content">
        <p class="dialog-title">联系我们了解更多详情：</p>
        <div class="contact-info">
          <div class="qr-code">
            <img src="@/assets/qr.png" alt="微信二维码" />
            <p>微信二维码</p>
          </div>
          <div class="qq-info">
            <p class="qq">QQ： 502801715</p>
            <p class="phone" @click="makeCall">电话：15178950401</p>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleStay">继续浏览</el-button>
        <el-button @click="handleLeave">确认离开</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showExitDialog: false,
      isLeaving: false,
      startUrl: "",
    };
  },
  created() {
    // 将 isLeaving 暴露到全局
    window.setIsLeaving = (value) => {
      this.isLeaving = value;
    };

    //  记录初始URL
    this.startUrl = window.location.href;

    //  添加历史记录条目
    window.history.pushState({ noBackExitPage: true }, "");

    //  监听popstate事件
    window.addEventListener("popstate", this.handlePopState);
  },
  mounted() {
    //  监听页面卸载
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeDestroy() {
    //  清理全局变量
    delete window.setIsLeaving;

    window.removeEventListener("popstate", this.handlePopState);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  methods: {
    handlePopState(e) {
      if (!this.isLeaving) {
        e.preventDefault();
        //  重新添加历史记录以防止返回
        window.history.pushState({ noBackExitPage: true }, "", this.startUrl);
        this.showExitDialog = true;
      }
    },
    handleBeforeUnload(e) {
      if (!this.isLeaving) {
        e.preventDefault();
        e.returnValue = "";
        this.showExitDialog = true;
        return "";
      }
    },
    handleStay() {
      this.showExitDialog = false;
    },
    handleLeave() {
      this.isLeaving = true;
      this.showExitDialog = false;

      //  退出逻辑
      //  如果直接关闭失败，则尝试返回
      if (window.history.length > 1) {
        window.history.go(-window.history.length + 1); //  返回到最开始的页面
      } else {
        window.location.href = "about:blank"; //  如果都失败了，重定向到空白页
      }
    },
    makeCall() {
      //  临时禁用退出拦截
      const originalIsLeaving = this.isLeaving;
      this.isLeaving = true;

      const phoneNumber = "15178950401";
      const isMobile = /Mobi|Android/i.test(navigator.userAgent);

      if (isMobile) {
        window.location.href = `tel:${phoneNumber}`;
      }

      //  恢复退出拦截状态
      setTimeout(() => {
        this.isLeaving = originalIsLeaving;
      }, 100);
    },
  },
};
</script>

<style lang="less">
.exit-dialog {
  @media screen and (max-width: 768px) {
    width: 300px !important;
    margin-top: 30vh !important;
  }
}

.exit-dialog-content {
  text-align: center;

  .dialog-title {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
    font-weight: bold;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    .qr-code {
      img {
        width: 150px;
        height: 150px;
        border: 1px solid #eee;
        padding: 5px;

        @media screen and (max-width: 768px) {
          width: 120px;
          height: 120px;
        }
      }

      p {
        margin-top: 8px;
        font-size: 14px;
        color: #666;
      }
    }

    .qq-info {
      p {
        font-size: 16px;
        color: #333;
        margin: 8px 0;
        font-weight: bold;
      }

      .phone {
        color: #00a985;
      }
    }
  }
}

.dialog-footer {
  .el-button {
    padding: 8px 20px;
    font-size: 14px;
  }
}
</style>
